// src/components/SignUp.js
import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';

const SignUp = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            await axiosInstance.post('/signup/', { username, password, email });
            const response = await axiosInstance.post('/token/', { username, password });
            localStorage.setItem('token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            window.location.href = `/profile/${username}`;
        } catch (error) {
            console.error('Sign up failed', error);
            setError('Sign up failed. Please try again.');
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center">
            <div className="card p-4" style={{ maxWidth: '400px', width: '100%' }}>
                <form onSubmit={handleSubmit} className="signup-form">
                    <h2 className="text-center mb-4">Sign Up</h2>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <div className="mb-3">
                        <label className="form-label">Username</label>
                        <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Confirm Password</label>
                        <input type="password" className="form-control" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Sign Up</button>
                </form>
            </div>
        </div>
    );
};

export default SignUp;
