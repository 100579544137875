// ArticleCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faComment } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import './ArticleCard.css'; // Ensure you have the CSS file for custom styles

const ArticleCard = ({ article }) => {
  const truncateContent = (htmlContent, maxLength = 150) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = DOMPurify.sanitize(htmlContent);
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    return textContent.length > maxLength ? textContent.substring(0, maxLength) + '...' : textContent;
  };

  return (
    <div className="card fixed-size-card">
      <div className="card-body">
        <h5 className="card-title">{article.title}</h5>
        <div className="card-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncateContent(article.content)) }}></div>
        <div className="d-flex justify-content-between align-items-center mt-auto">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faFire} className="me-2 text-primary" aria-label="Upvotes" />
            <span className="badge bg-primary">{article.upvotes_count}</span>
          </div>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faComment} className="me-2 text-danger" aria-label="Comments" />
            <span className="badge bg-danger">{article.comments_count}</span>
          </div>
          <Link to={`/articles/${article.id}`} className="btn btn-primary">
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;