// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Login from './components/Login';
import SignUp from './components/SignUp';
import UserProfile from './components/UserProfile';
import LandingPage from './components/LandingPage';
import Activities from './components/Activities';
import Events from './components/Events';
import EventView from './components/EventView'; // Import the EventView component
import EventForm from './components/EventForm';
import Users from './components/Users';
import Articles from './components/Articles';
import ArticleView from './components/ArticleView';
import ArticleForm from './components/ArticleForm';
import Navbar from './components/Navbar'; // Import the Navbar component
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <Helmet>
                    <title>Kora</title>
                </Helmet>
                <Navbar />
                <div className="pt-5 mt-5">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/profile/:username" element={<UserProfile />} />
                        <Route path="/activities" element={<Activities />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/articles" element={<Articles />} />
                        <Route path="/articles/:sport" element={<Articles />} />
                        <Route path="/articles/:id" element={<ArticleView />} />
                        <Route path="/articles/create" element={<ArticleForm />} /> 
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/events/create" element={<EventForm />} />
                        <Route path="/events/:id" element={<EventView />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
