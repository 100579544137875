import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import EventCard from './EventCard'; // Assuming you have an EventCard component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMale, faFemale, faGenderless, faCalendarAlt, faMapMarkerAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const UserProfile = () => {
    const { username } = useParams();
    const [profile, setProfile] = useState(null);
    const [loggedInUsername, setLoggedInUsername] = useState(null);
    const [events, setEvents] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const countryList = [
        "Singapore", "United States", "United Kingdom", "Australia", "Canada", "Germany", "France", "Italy", "Spain",
        "Netherlands", "Sweden", "Norway", "Denmark", "Finland", "Belgium", "Switzerland", "Austria", "Ireland",
        "New Zealand", "Japan", "South Korea", "China", "India", "Brazil", "Mexico", "Russia", "South Africa",
        "Argentina", "Chile", "Colombia", "Peru", "Venezuela"
    ];

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axiosInstance.get(`/profiles/u-${username}/`);
                setProfile(response.data);
            } catch (error) {
                console.error('Failed to fetch profile', error);
            }
        };

        const fetchUserEvents = async () => {
            try {
                const response = await axiosInstance.get(`/events/user/${username}/`);
                setEvents(response.data);
            } catch (error) {
                console.error('Failed to fetch events', error);
            }
        };

        const fetchLoggedInUsername = async () => {
            try {
                const response = await axiosInstance.get('/profiles/me/');
                setLoggedInUsername(response.data.user);
            } catch (error) {
                console.error('Failed to fetch logged-in username', error);
            }
        };

        fetchProfile();
        fetchUserEvents();
        fetchLoggedInUsername();
    }, [username]);

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleAvatarUpload(file);
        }
    };

    const handleAvatarUpload = async (file) => {
        const formData = new FormData();
        formData.append('avatar', file);

        try {
            await axiosInstance.put('/profiles/me/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            window.location.reload();  // Refresh the page to see the updated avatar
        } catch (error) {
            console.error('Failed to upload avatar', error);
        }
    };

    const handleProfileUpdate = async (field, value) => {
        try {
            const response = await axiosInstance.put('/profiles/me/', {
                [field]: value,
            });
            setProfile(response.data);
        } catch (error) {
            console.error(`Failed to update ${field}`, error);
        }
    };

    const handleSaveChanges = async () => {
        await handleProfileUpdate('description', profile.description);
        await handleProfileUpdate('city', profile.city);
        await handleProfileUpdate('area', profile.area);
        await handleProfileUpdate('country', profile.country);
        await handleProfileUpdate('is_coach', profile.is_coach);
        window.location.reload();  // Refresh the page to see the updated profile
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleModalClose = () => setShowModal(false);

    if (!profile) {
        return <div>Loading...</div>;
    }

    const genderIcon = profile.gender === 'Male' ? faMale : profile.gender === 'Female' ? faFemale : faGenderless;
    const isCurrentUser = loggedInUsername === profile.user;

    return (
        <div className="container" style={{ maxWidth: '700px' }}>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="position-relative">
                                {profile.avatar && (
                                    <img src={`${profile.avatar}`} alt="Avatar" className="rounded-circle me-3" width="100" height="100" style={{ cursor: 'pointer' }} onClick={() => document.getElementById('avatarUpload').click()} />
                                )}
                                <input type="file" id="avatarUpload" onChange={handleAvatarChange} style={{ display: 'none' }} />
                            </div>
                            <div>
                                <h6 className="card-title">{profile.user}</h6>
                                <p className="card-text"><FontAwesomeIcon icon={genderIcon} /> {profile.gender}</p>
                                <p className="card-text"><FontAwesomeIcon icon={faCalendarAlt} /> {new Date().getFullYear() - profile.year_of_birth} years old</p>
                                <p className="card-text"><FontAwesomeIcon icon={faMapMarkerAlt} /> {profile.area}, {profile.city}, {profile.country}</p>
                                {profile.is_coach && <span className="badge bg-primary">{profile.coach_sport} Coach</span>}
                            </div>
                        </div>
                        <div>
                            {isCurrentUser && (
                                <div className="dropdown">
                                    <button className="btn btn-link" onClick={toggleMenu}>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </button>
                                    {isMenuOpen && (
                                        <div className="dropdown-menu show" style={{ position: 'absolute', right: 0 }}>
                                            <button className="dropdown-item" onClick={() => document.getElementById('avatarUpload').click()}>Change Avatar</button>
                                            <button className="dropdown-item" onClick={() => setShowModal(true)}>Edit Profile</button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {profile.description && <p className="mt-3">{profile.description}</p>}
                </div>
            </div>
            <h6 className="mt-5">My Sports</h6>
            <div className="d-flex flex-wrap">
                {profile.sport_levels && Object.entries(profile.sport_levels).map(([sport, level]) => (
                    <div key={sport} className="badge bg-secondary m-1">
                        {sport}: {level}
                    </div>
                ))}
            </div>
            <h3 className="my-4">My Events</h3>
            <div className="row">
                {events.length > 0 ? (
                    events.map(event => (
                        <div key={event.id} className="col-md-4 mb-4">
                            <EventCard event={event} />
                        </div>
                    ))
                ) : (
                    <div className="col-12">
                        <div className="no-posts text-center p-4 border rounded">
                            <p>{profile.user} hasn't posted any events yet.</p>
                        </div></div>
                    )}
            </div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea className="form-control" value={profile.description} onChange={(e) => setProfile({ ...profile, description: e.target.value })} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">City</label>
                        <input type="text" className="form-control" value={profile.city} onChange={(e) => setProfile({ ...profile, city: e.target.value })} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Area</label>
                        <input type="text" className="form-control" value={profile.area} onChange={(e) => setProfile({ ...profile, area: e.target.value })} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Country</label>
                        <select className="form-control" value={profile.country} onChange={(e) => setProfile({ ...profile, country: e.target.value })}>
                            {countryList.map(country => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="isCoach" checked={profile.is_coach} onChange={(e) => setProfile({ ...profile, is_coach: e.target.checked })} />
                        <label className="form-check-label" htmlFor="isCoach">Are you a coach?</label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserProfile;
