// src/components/Users.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';

const Users = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axiosInstance.get('/users/');
                setUsers(response.data);
            } catch (error) {
                console.error('Failed to fetch users', error);
            }
        };
        fetchUsers();
    }, []);

    return (
        <div className="container">
            <h2 className="my-4">Users</h2>
            <div className="list-group">
                {users.map(user => (
                    <div key={user.id} className="list-group-item">
                        <h5>{user.username}</h5>
                        <p><strong>Email:</strong> {user.email}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Users;
