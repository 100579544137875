// // src/axiosConfig.js
// import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/api';

// const axiosInstance = axios.create({
//     baseURL: API_URL,
//     timeout: 20000,
//     headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json',
//     },
// });

// axiosInstance.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => Promise.reject(error)
// );

// axiosInstance.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response && error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             try {
//                 const refreshToken = localStorage.getItem('refresh_token');
//                 const response = await axiosInstance.post('/token/refresh/', { refresh: refreshToken });
//                 localStorage.setItem('token', response.data.access);
//                 originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
//                 return axiosInstance(originalRequest);
//             } catch (err) {
//                 console.error('Token refresh failed', err);
//                 // Handle refresh token failure (e.g., logout user)
//             }
//         }
//         return Promise.reject(error);
//     }
// );

// export default axiosInstance;
// src/axiosConfig.js

// src/axiosConfig.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/api';

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
    refreshSubscribers.map((cb) => cb(token));
}

function forceLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/login';
}

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;

                if (isRefreshing) {
                    return new Promise((resolve) => {
                        subscribeTokenRefresh((token) => {
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            resolve(axiosInstance(originalRequest));
                        });
                    });
                }

                isRefreshing = true;

                const refreshToken = localStorage.getItem('refresh_token');
                if (!refreshToken) {
                    forceLogout();
                    return Promise.reject(error);
                }

                return axiosInstance.post('/token/refresh/', { refresh: refreshToken })
                    .then(({ data }) => {
                        localStorage.setItem('token', data.access);
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
                        onRrefreshed(data.access);
                        refreshSubscribers = [];
                        return axiosInstance(originalRequest);
                    })
                    .catch((err) => {
                        forceLogout();
                        return Promise.reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
