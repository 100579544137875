// src/components/Activities.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';

const Activities = () => {
    const [activities, setActivities] = useState([]);
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [cost, setCost] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await axiosInstance.get('/activities/');
                setActivities(response.data);
            } catch (error) {
                console.error('Failed to fetch activities', error);
            }
        };
        const fetchUser = async () => {
            try {
                const response = await axiosInstance.get('/profiles/me/');
                setUser(response.data.user);
            } catch (error) {
                console.error('Failed to fetch user', error);
            }
        };
        fetchActivities();
        fetchUser();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/activities/', {
                name,
                location,
                description,
                cost,
                created_by: user.id
            });
            setActivities([...activities, response.data]);
            setName('');
            setLocation('');
            setDescription('');
            setCost('');
        } catch (error) {
            console.error('Failed to create activity', error);
        }
    };

    return (
        <div className="container">
            <h2 className="my-4">Activities</h2>
            <form onSubmit={handleSubmit} className="mb-4">
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Location</label>
                    <input type="text" className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Cost</label>
                    <input type="number" className="form-control" value={cost} onChange={(e) => setCost(e.target.value)} required />
                </div>
                <button type="submit" className="btn btn-primary">Create Activity</button>
            </form>
            <div className="list-group">
                {activities.map(activity => (
                    <div key={activity.id} className="list-group-item">
                        <h5>{activity.name}</h5>
                        <p>{activity.description}</p>
                        <p><strong>Location:</strong> {activity.location}</p>
                        <p><strong>Cost:</strong> {activity.cost}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Activities;
