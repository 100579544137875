import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import ArticleCard from './ArticleCard';
import { useParams } from 'react-router-dom';

const Articles = () => {
    const { sport } = useParams();  // Get the sport parameter from the URL
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const endpoint = sport ? `/articles/by_sport/?sport=${sport}` : '/articles/';
                const response = await axiosInstance.get(endpoint);
                setArticles(response.data);
            } catch (error) {
                console.error('Failed to fetch articles', error);
            }
        };
        fetchArticles();
    }, [sport]);  // Re-run this effect when the sport parameter changes

    return (
        <div className="container">
            <h2 className="my-4">Articles {sport && ` - ${sport}`}</h2>
            <div className="row">
                {articles.map(article => (
                    <div key={article.id} className="col-md-4 mb-4">
                        <ArticleCard article={article} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Articles;
