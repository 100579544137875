import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import DOMPurify from 'dompurify';
import './ArticleView.css';

const ArticleView = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    const fetchArticle = async () => {
      if (id) {
        try {
          const response = await axiosInstance.get(`/articles/${id}/`);
          setArticle(response.data);

          const commentsResponse = await axiosInstance.get(`/articles/${id}/comments/`);
          setComments(commentsResponse.data);
        } catch (error) {
          console.error('Failed to fetch article:', error);
        }
      }
    };

    fetchArticle();
  }, [id]);

  const handleUpvote = async () => {
    if (!article) return;

    try {
      const response = await axiosInstance.post(`/articles/${id}/upvote/`);
      const { upvotes_count, message } = response.data.article;

      setArticle((prevArticle) => ({
        ...prevArticle,
        upvotes_count,
        has_upvoted: message === 'Upvote added',
      }));
    } catch (error) {
      console.error('Failed to upvote article:', error);
    }
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    if (!newComment) return;

    try {
      const response = await axiosInstance.post(`/articles/${id}/create_comment/`, {
        content: newComment,
      });
      setComments([...comments, response.data]);
      setNewComment('');
      setArticle((prevArticle) => ({
        ...prevArticle,
        comments_count: prevArticle.comments_count + 1,
      }));
    } catch (error) {
      console.error('Failed to submit comment:', error);
    }
  };

  return (
    <div className="article-view container mt-4">
      {article && (
        <>
          <div className="article-content mb-4">
            <h2 className="article-title">{article.title}</h2>
            <p className="article-subtitle mb-2 text-muted">By {article.author}</p>
            <div className="article-body mb-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}></div>
          </div>

          <div className="article-actions d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <button onClick={handleUpvote} className="btn btn-light btn-sm me-2">
                <i className={`bi bi-arrow-up ${article.has_upvoted ? 'text-primary' : ''}`}></i>
                {article.upvotes_count}
              </button>
              <span className="comment-count">
                <i className="bi bi-chat-left-text"></i> {article.comments_count}
              </span>
            </div>
            <button className="btn btn-light btn-sm">
              <i className="bi bi-share"></i> Share
            </button>
          </div>

          <div className="comments-section">
            <form onSubmit={handleCommentSubmit} className="mb-4">
              <div className="input-group">
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Write your comment..."
                  className="form-control"
                />
                <button type="submit" className="btn btn-primary">Comment</button>
              </div>
            </form>

            <ul className="list-group mb-4">
              {comments.length > 0 ? (
                comments.map((comment) => (
                  <li key={comment.id} className="list-group-item d-flex justify-content-between align-items-start">
                    <div>
                      <p className="mb-1">{comment.content}</p>
                      <small className="text-muted">By {comment.author} on {new Date(comment.created_at).toLocaleDateString()}</small>
                    </div>
                  </li>
                ))
              ) : (
                <li className="list-group-item">No comments yet. Be the first to comment!</li>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ArticleView;
