// src/components/EventView.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faDollarSign, faUsers } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../axiosConfig';
import './EventView.css';

const EventView = () => {
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await axiosInstance.get(`/events/${id}/`);
                setEvent(response.data);
            } catch (error) {
                console.error('Failed to fetch event:', error);
            }
        };

        const fetchUser = async () => {
            try {
                const response = await axiosInstance.get('/profiles/me/');
                setCurrentUser(response.data.user);
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        };

        fetchEvent();
        fetchUser();
    }, [id]);

    const handleJoinWaitlist = async () => {
        try {
            const response = await axiosInstance.post(`/events/${id}/join_event/`);
            setEvent((prevEvent) => ({
                ...prevEvent,
                waitlist: [...prevEvent.waitlist, response.data],
            }));
            alert('You have been waitlisted.');
        } catch (error) {
            console.error('Failed to join waitlist:', error);
            alert('Failed to join waitlist.');
        }
    };

    const handleApproveParticipant = async (participantId) => {
        try {
            await axiosInstance.post(`/events/${id}/approve_participant/`, { participant_id: participantId });
            setEvent((prevEvent) => ({
                ...prevEvent,
                waitlist: prevEvent.waitlist.filter(user => user.id !== participantId),
                participants: [...prevEvent.participants, prevEvent.waitlist.find(user => user.id === participantId)],
            }));
            alert('Participant approved.');
        } catch (error) {
            console.error('Failed to approve participant:', error);
            alert('Failed to approve participant.');
        }
    };

    const handleRemoveFromWaitlist = async (participantId) => {
        try {
            await axiosInstance.post(`/events/${id}/remove_from_waitlist/`, { participant_id: participantId });
            setEvent((prevEvent) => ({
                ...prevEvent,
                waitlist: prevEvent.waitlist.filter(user => user.id !== participantId),
            }));
            alert('Participant removed from waitlist.');
        } catch (error) {
            console.error('Failed to remove participant from waitlist:', error);
            alert('Failed to remove participant from waitlist.');
        }
    };

    if (!event || !currentUser) {
        return <div>Loading...</div>;
    }

    const isParticipant = event.participants.some(p => p.id === currentUser.id);

    return (
        <div className="container mt-4">
            {event && (
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">{event.name}</h2>
                        <span className="badge badge-info mb-3">{event.sport}</span>
                        <p className="card-text">{event.description}</p>
                        <div className="event-details">
                            <div className="d-flex align-items-center mb-2">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 text-primary" aria-label="Location" />
                                <span>{event.location}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <FontAwesomeIcon icon={faCalendarAlt} className="me-2 text-danger" aria-label="Date" />
                                <span>{new Date(event.date).toLocaleString()}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <FontAwesomeIcon icon={faDollarSign} className="me-2 text-success" aria-label="Cost" />
                                <span>{event.cost ? `$${event.cost}` : 'Free'}</span>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <FontAwesomeIcon icon={faUsers} className="me-2 text-warning" aria-label="Participants" />
                                <span>{`${event.participants.length}/${event.max_participants}`}</span>
                            </div>
                        </div>
                        <button onClick={handleJoinWaitlist} className="btn btn-primary w-100 mt-3" disabled={isParticipant}>
                            {isParticipant ? 'You have joined' : 'Join Waitlist'}
                        </button>
                        {currentUser.id === event.organizer.id && (
                            <div className="waitlist mt-4">
                                <h4>Waitlist</h4>
                                <ul className="list-group">
                                    {event.waitlist.map(user => (
                                        <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
                                            {user.username}
                                            <div>
                                                <button onClick={() => handleApproveParticipant(user.id)} className="btn btn-success btn-sm me-2">Approve</button>
                                                <button onClick={() => handleRemoveFromWaitlist(user.id)} className="btn btn-danger btn-sm">Remove</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventView;
