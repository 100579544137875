// src/components/EventCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faUser, faUsers, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import axiosInstance from '../axiosConfig';
import './EventCard.css';

const EventCard = ({ event, onJoin }) => {
  const truncateDescription = (htmlContent, maxLength = 100) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = DOMPurify.sanitize(htmlContent);
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    return textContent.length > maxLength ? textContent.substring(0, maxLength) + '...' : textContent;
  };

  const handleJoin = async (e) => {
    e.stopPropagation(); // Prevent triggering the card's link
    try {
      await axiosInstance.post(`/events/${event.id}/join/`);
      if (onJoin) {
        onJoin();
      }
    } catch (error) {
      console.error('Failed to join event:', error);
    }
  };

  return (
    <Link to={`/events/${event.id}`} className="card-link">
      <div className="card fixed-size-card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h6 className="card-title">{event.name}</h6>
            <span className="badge badge-info">{event.sport}</span>
          </div>
          <div className="event-details mb-3">
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 text-primary" aria-label="Location" />
              <span>{event.location}</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faCalendarAlt} className="me-2 text-danger" aria-label="Date" />
              <span>{new Date(event.date).toLocaleString()}</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faDollarSign} className="me-2 text-success" aria-label="Cost" />
              <span>{event.cost ? `$${event.cost}` : 'Free'}</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faUser} className="me-2 text-info" aria-label="Organizer" />
              <span>{event.organizer.username}</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faUsers} className="me-2 text-warning" aria-label="Participants" />
              <span>{`${event.participants.length}/${event.max_participants}`}</span>
            </div>
          </div>
          <button onClick={handleJoin} className="btn btn-success w-100 mt-auto">
            Join
          </button>
        </div>
      </div>
    </Link>
  );
};

export default EventCard;
