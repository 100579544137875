import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faDollarSign, faRunning, faUsers, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import './EventForm.css'; // Ensure you have the CSS file for custom styles

const EventForm = () => {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [cost, setCost] = useState('');
    const [sport, setSport] = useState('Tennis');
    const [maxParticipants, setMaxParticipants] = useState('');
    const [waitlistEnabled, setWaitlistEnabled] = useState(false);
    const navigate = useNavigate();

    const sportsOptions = ['Tennis', 'Football', 'Basketball', 'Surfing', 'Gym', 'Running', 'Cycling', 'Yoga', 'HIIT'];

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/events/', {
                name,
                location,
                date,
                description,
                cost,
                sport,
                max_participants: maxParticipants,
                waitlist_enabled: waitlistEnabled,
            });
            navigate(`/events/${response.data.id}`);
        } catch (error) {
            console.error('Failed to create event', error);
        }
    };

    return (
        <div className="container">
            <div className="form-container">
                <h2 className="my-4">Create Event</h2>
                <form onSubmit={handleSubmit} className="mb-4">
                    <div className="mb-3">
                        <label className="form-label">Name</label>
                        <div className="input-group">
                            <FontAwesomeIcon icon={faRunning} className="input-group-text" />
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Location</label>
                        <div className="input-group">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="input-group-text" />
                            <input type="text" className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Date</label>
                        <div className="input-group">
                            <FontAwesomeIcon icon={faCalendarAlt} className="input-group-text" />
                            <input type="datetime-local" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Cost</label>
                        <div className="input-group">
                            <FontAwesomeIcon icon={faDollarSign} className="input-group-text" />
                            <input type="number" className="form-control" value={cost} onChange={(e) => setCost(e.target.value)} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Sport</label>
                        <select className="form-control" value={sport} onChange={(e) => setSport(e.target.value)} required>
                            {sportsOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Max Participants</label>
                        <div className="input-group">
                            <FontAwesomeIcon icon={faUsers} className="input-group-text" />
                            <input type="number" className="form-control" value={maxParticipants} onChange={(e) => setMaxParticipants(e.target.value)} required />
                        </div>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="waitlistEnabled" checked={waitlistEnabled} onChange={(e) => setWaitlistEnabled(e.target.checked)} />
                        <label className="form-check-label" htmlFor="waitlistEnabled">
                            <FontAwesomeIcon icon={faCheckSquare} className="me-2" />
                            Enable Waitlist
                        </label>
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Create Event</button>
                </form>
            </div>
        </div>
    );
};

export default EventForm;
