import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import logo from '../brand/kora_navbar.png';

const Navbar = () => {
    const [user, setUser] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axiosInstance.get('/profiles/me/');
                setUser(response.data);
            } catch (error) {
                console.error('Failed to fetch user', error);
            }
        };
        if (localStorage.getItem('token')) {
            fetchUser();
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        setUser(null);
        window.location.href = '/login';
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const guideLink = document.querySelector('.nav-link-guide');
        const navbarThirdRow = document.querySelector('.navbar-third');

        const showNavbarThirdRow = () => {
            navbarThirdRow.classList.remove('d-none');
        };

        const hideNavbarThirdRow = () => {
            navbarThirdRow.classList.add('d-none');
        };

        if (guideLink && navbarThirdRow) {
            guideLink.addEventListener('mouseenter', showNavbarThirdRow);
            navbarThirdRow.addEventListener('mouseleave', hideNavbarThirdRow);
        }

        return () => {
            if (guideLink && navbarThirdRow) {
                guideLink.removeEventListener('mouseenter', showNavbarThirdRow);
                navbarThirdRow.removeEventListener('mouseleave', hideNavbarThirdRow);
            }
        };
    }, []);

    return (
        <div className="navbar-container">
            <nav className="navbar navbar-expand-lg navbar-light navbar-top">
                <div className="container">
                    <div className="row w-100">
                        <div className="col-4 d-flex justify-content-start">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                            <Link className="navbar-brand" to="/">
                                <img src={logo} alt="Koradev Logo" height="30" className="mr-2" />
                            </Link>
                        </div>
                        <div className="col-4 d-flex justify-content-end">
                            <ul className="navbar-nav">
                                {user ? (
                                    <>
                                        <li className="nav-item dropdown" ref={dropdownRef}>
                                            <button
                                                className="btn btn-link nav-link dropdown-toggle p-0"
                                                onClick={toggleDropdown}
                                                aria-haspopup="true"
                                                aria-expanded={isDropdownOpen}
                                            >
                                                <img src={user.avatar} alt="Avatar" className="rounded-circle" height="30" width="30" />
                                            </button>
                                            <div className={`dropdown-menu dropdown-menu-right ${isDropdownOpen ? 'show' : ''}`}>
                                                <Link className="dropdown-item" to={`/profile/${user.user}`}>My Profile</Link>
                                                {user.is_admin && <Link className="dropdown-item" to="/articles/create">Create Article</Link>}
                                                <Link className="dropdown-item" to="/events/create">Create Event</Link>
                                                <div className="dropdown-divider"></div>
                                                <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                                            </div>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/login">Login</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/signup">Sign Up</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-dark navbar-second">
                <div className="container">
                    <div className="row w-100">
                        <div className="col d-flex justify-content-center">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link nav-link-guide" to="/articles">Guide</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/events">Events</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-light navbar-third d-none">
                <div className="container">
                    <div className="row w-100">
                        <div className="col d-flex justify-content-center">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/articles/Gym">Gym</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/articles/Yoga">Yoga</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/articles/Tennis">Tennis</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/articles/Running">Running</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/articles/Bootcamps">Bootcamps</Link>
                                </li>
                                {user && user.is_admin && (
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/articles/create">Create Article</Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
