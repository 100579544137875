// src/components/LandingPage.js
import React from 'react';

const LandingPage = () => {
    return (
        <div className="container text-center py-5">
            <h1 className="display-4 text-primary">Welcome to Kora</h1>
            <p className="lead">Your go-to platform for sports activities and reviews.</p>
        </div>
    );
};

export default LandingPage;
