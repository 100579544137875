// src/components/Events.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import EventCard from './EventCard'; // Import the EventCard component

const Events = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axiosInstance.get('/events/');
                setEvents(response.data);
            } catch (error) {
                console.error('Failed to fetch events', error);
            }
        };
        fetchEvents();
    }, []);

    const handleJoinEvent = async () => {
        try {
            const response = await axiosInstance.get('/events/');
            setEvents(response.data);
        } catch (error) {
            console.error('Failed to fetch events:', error);
        }
    };

    return (
        <div className="container">
            <h2 className="my-4">Events</h2>
            <div className="row">
                {events.map(event => (
                    <div key={event.id} className="col-md-4 mb-4">
                        <EventCard event={event} onJoin={handleJoinEvent} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Events;
